/**
 * @file AuthProvider.js
 * @description Provides authentication functionality using Azure Active Directory.
 */

import { jwtDecode } from 'jwt-decode'
import { MsalProvider } from '@azure/msal-react'
import { useLayoutEffect, useState } from 'preact/hooks'
import { PublicClientApplication } from '@azure/msal-browser'

import { AuthContext } from 'contexts'

import { AUTHORIZATION_ROLES } from 'data'

/**
 * Configuration object for MSAL (Microsoft Authentication Library).
 * @type {Object}
 */
const MSAL_CONFIG = {
  auth: {
    clientId: process.env.MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.MSAL_TENANT_ID}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
}

const LOGIN_SCOPES = [`${process.env.MSAL_CLIENT_ID}/.default`]

const MSAL_INSTANCE = new PublicClientApplication(MSAL_CONFIG)

const initializeMSAL = async () => {
  await MSAL_INSTANCE.initialize()
}

const AuthProvider = ({ needsAuthorization, children }) => {
  const [account, setAccount] = useState()
  const [accessToken, setAccessToken] = useState()

  useLayoutEffect(() => {
    if (!needsAuthorization) return

    handleAuthFlow()
  }, [needsAuthorization])

  /**
   * Handles the authentication flow.
   * @returns {Promise<void>} A promise that resolves when the authentication flow is complete.
   */
  const handleAuthFlow = async () => {
    await initializeMSAL().then(() => {
      MSAL_INSTANCE.handleRedirectPromise()
        .then(async (response) => {
          if (response !== null) {
            setAccessToken(response.accessToken)
            setAccount(response)
          } else {
            const accounts = MSAL_INSTANCE.getAllAccounts()

            if (accounts.length > 0) {
              MSAL_INSTANCE.setActiveAccount(accounts[0])
              setAccount(accounts[0])
              await refreshToken()
            } else {
              await signIn()
            }
          }
        })
        .catch((error) => {
          console.error('Error processing redirect:', error)
        })
    })
  }

  /**
   * Sign in function that initiates the login process and sets the account if the user has the required roles.
   */
  const signIn = async () => {
    const loginRequest = {
      scopes: LOGIN_SCOPES,
      redirectStartPage: `${window.location.href}`,
    }
    try {
      const response = await MSAL_INSTANCE.loginRedirect(loginRequest)

      if (response?.account) {
        // Check if the user has the required roles
        const decodedToken = jwtDecode(response.accessToken)
        if (
          !decodedToken?.roles.some((role) =>
            AUTHORIZATION_ROLES.includes(role)
          )
        ) {
          return
        }

        setAccount(response)
      }
    } catch (error) {
      console.error('Error signing in', error)
    }
  }

  /**
   * Signs the user out by performing a logout redirect.
   */
  const signOut = () => {
    MSAL_INSTANCE.logoutRedirect()
  }

  /**
   * Refreshes the access token for the authenticated user.
   * If the token refresh fails, it fallsback to the sign-in method.
   * @returns {Promise<void>} A promise that resolves when the token refresh is complete.
   */
  const refreshToken = async () => {
    try {
      const currentAccount = MSAL_INSTANCE.getActiveAccount()
      if (!currentAccount)
        throw Error(
          'No active account! Verify a user has been signed in and setActiveAccount has been called.'
        )

      const silentRequest = {
        scopes: LOGIN_SCOPES,
        account: currentAccount,
      }

      const response = await MSAL_INSTANCE.acquireTokenSilent(silentRequest)

      setAccessToken(response.accessToken)
      setAccount(response.account)
    } catch (error) {
      console.error('Failed to refresh token', error)
      // Fallback to interactive method if silent token acquisition fails
      await signIn()
    }
  }

  return (
    <MsalProvider instance={MSAL_INSTANCE}>
      <AuthContext.Provider
        value={{ account, accessToken, signIn, signOut, refreshToken }}
      >
        {children}
      </AuthContext.Provider>
    </MsalProvider>
  )
}

export default AuthProvider
