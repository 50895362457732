export const TOP_LAYER = 'TopLayer'
export const MID_LAYER = 'MidLayer'
export const TIE_LAYER = 'TieLayer'
export const WAISTCOAT_LAYER = 'WaistCoatLayer'
export const BOTTOM_LAYER = 'BottomLayer'
export const SHOE_LAYER = 'ShoeLayer'
export const COAT_LAYER = 'CoatLayer'
export const SUIT_LAYER = 'SuitLayer'
export const MODEL_LAYER = 'ModelLayer'

// use for categories that are rendered, but not selectable e.g. a waistcoat
export const HIDDEN_LAYER = 'HiddenLayer'
export const TEMP_ARRAY_LENGTH = 6

export const OPTIONAL_LAYERS = [COAT_LAYER, TOP_LAYER]
export const SINGULAR_LAYERS = [TOP_LAYER, MID_LAYER, COAT_LAYER]

export const SORT_OBJECT = [
  COAT_LAYER,
  TOP_LAYER,
  MID_LAYER,
  BOTTOM_LAYER,
  SHOE_LAYER,
]
export const LOCALSTORAGE_KEY = 'suitGuideClosed'

export const SEPERATES_MODE = 'SeperatesMode'
export const SUITS_MODE = 'SuitsMode'
export const VIEW_MODE = 'ViewMode'
export const MODEL_SKIN = 'ModelLayer'
export const OUTOFSTOCK_STRING = 'OutOfStock'

export const APP_MODES = [SEPERATES_MODE, SUITS_MODE]

export const FALLBACK_UNDERSCORE = '60'
export const FALLBACK_MODEL = 'model_4'

export const UNDERSCORES_STRINGS = {
  thumbnail: 'Thumbnail',
  default: 'Default',
  openWide: 'Open Wide',
  closedWide: 'Closed Wide',
  openNarrow: 'Open Narrow',
  closedNarrow: 'Closed Narrow',
  underscoreTable: 'Underscores',
  matrixTable: 'Layer Assignment Lookbuilder Products',
  modelTable: 'Models',
}

export const VISUAL_SORT_OBJECT = [
  TOP_LAYER,
  BOTTOM_LAYER,
  MID_LAYER,
  SHOE_LAYER,
  COAT_LAYER,
]

export const INITIAL_PRODUCT_OPTIONS_OBJECT = [
  TOP_LAYER,
  BOTTOM_LAYER,
  MID_LAYER,
  SHOE_LAYER,
  COAT_LAYER,
  SUIT_LAYER,
  '',
]

export const FUNCTIONAL_SORT_OBJECT = [
  TOP_LAYER,
  BOTTOM_LAYER,
  MID_LAYER,
  SHOE_LAYER,
  COAT_LAYER,
  MODEL_LAYER,
]

export const SUMMARY_SORT_OBJECT = [
  TOP_LAYER,
  MID_LAYER,
  BOTTOM_LAYER,
  SHOE_LAYER,
  COAT_LAYER,
]

export const WISHLIST_SORT_OBJECT = [
  COAT_LAYER,
  TOP_LAYER,
  TIE_LAYER,
  MID_LAYER,
  BOTTOM_LAYER,
  SHOE_LAYER,
]

export const LAYER_MAPPING = {
  0: TOP_LAYER,
  2: BOTTOM_LAYER,
}

export const SUIT_LAYER_MAPPING = {
  1: MID_LAYER,
  3: SHOE_LAYER,
  4: COAT_LAYER,
}

export const DEFAULT_PRODUCTS_IDS = {
  INT: ['C7181', 'SW1100', 'B1259', 'FW1480'],
  USA: ['C7181', 'SW1100', 'B1259', 'FW1480'],
  APAC: ['C1934A', 'SW1100', 'B1259', 'FW1480'],
}

export const SKIN_COLORS = [
  { tone: '1', id: '1', name: 'Kiko' },
  { tone: '2', id: '2', name: 'Fifi' },
  { tone: '3', id: '3', name: 'Robin' },
  { tone: '4', id: '4', name: 'Jay' },
  { tone: '5', id: '5', name: 'Nova' },
  { tone: '6', id: '6', name: 'Nova' },
  { tone: '7', id: '7', name: 'Nova' },
  { tone: '8', id: '8', name: 'Nova' },
  { tone: '9', id: '9', name: 'Nova' },
  { tone: '10', id: '10', name: 'Nova' },
]

// Validate the model string using the actual IDs from SKIN_COLORS
export const MODEL_REGEX = new RegExp(
  `^model_(${SKIN_COLORS.map((color) => color.id).join('|')})$`
)

// MODEL_NAMES can now be derived from SKIN_COLORS
export const MODEL_NAMES = SKIN_COLORS.map((color) => color.name)

export const MODEL_SET = SKIN_COLORS.map((color) => ({
  id: color.id,
  category: 'ModelSelection',
  alt: `Model ${color.name}`,
}))

export const ADDITIONAL_CATEGORIES = {
  ModelSelection: {
    layerName: MODEL_LAYER,
    category: 'ModelSelection',
    subCategory: 'ModelSelection',
    labelKey: 'skin-tone',
    componentKey: 'ModelSelection',
  },
}

export const CATEGORIES = {
  Jackets: ['Jackets'],
  Shirts: ['Shirts', 'Knitwear'],
  Trousers: ['Trousers', 'Shorts'],
  Shoes: ['Shoes'],
}

export const LAYER_REVERSE_MAP = {
  [TOP_LAYER]: 'Jackets',
  [BOTTOM_LAYER]: 'Trousers',
  [MID_LAYER]: 'Shirts',
  [SHOE_LAYER]: 'Shoes',
  [COAT_LAYER]: 'Coats',
}

export const PLACEHOLDER_PRODUCT = {
  id: '0',
  name: OUTOFSTOCK_STRING,
  category: OUTOFSTOCK_STRING,
  alt: OUTOFSTOCK_STRING,
  subCategory: OUTOFSTOCK_STRING,
  layerName: OUTOFSTOCK_STRING,
  onlineProductType: OUTOFSTOCK_STRING,
  enabledForDefaultLook: OUTOFSTOCK_STRING,
  material: OUTOFSTOCK_STRING,
  price: OUTOFSTOCK_STRING,
}

export const LS_LOCALE_KEY = 'lastLocale'
export const LS_MODEL_SELECTION = 'modelSelectedNew'

export const AUTHORIZATION_PARAM = 'needsAuthorization'
export const AUTHORIZATION_ROLES = ['Look.Submit']

export const DATA_SOURCE_PARAM = 'Origin'

export const ORIGIN_WEBSTORE = 'Webstore'
export const ORIGIN_STORE = 'Store'
export const LOCALE_PARAM_STRING = 'Locale'

export const PORTAL_SCANNER_KEY = 'PortalScanner'

export const AVAILABLE_ORIGINS = [ORIGIN_WEBSTORE, ORIGIN_STORE]

export const ENVIRONMENT_OPTIONS = {
  TESTING: 'TESTING',
  ACCEPTANCE: 'ACCEPTANCE',
  PRODUCTION: 'PRODUCTION',
}

export const STANDALONE_ENVIRONMENTS = {
  [ENVIRONMENT_OPTIONS.TESTING]: 'https://testing.lookbuilder.suitsupply.com/',
  [ENVIRONMENT_OPTIONS.ACCEPTANCE]:
    'https://acceptance.lookbuilder.suitsupply.com/',
  [ENVIRONMENT_OPTIONS.PRODUCTION]: 'https://lookbuilder.suitsupply.com/',
}

export const EXCEPTION_REGEX = /(three|Three)-?\W?(piece|Piece)?/i
